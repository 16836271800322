import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'

const TabFilter = ({ filterName, tabs }) => {
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)

  const activeKey = searchParams.get(filterName) || 'Online'

  return (
    <Tabs
      className='mt-3'
      items={tabs}
      activeKey={activeKey}
      onTabClick={(key) => {
        searchParams.set(filterName, key)
        history.push({
          pathname: history.location.pathname,
          search: searchParams.toString(),
        })
      }}
    />
  )
}

export default TabFilter
