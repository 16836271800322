import { combineReducers } from 'redux'

import Theme, { languageReducer } from './Theme'

// AUTH
import {
  userAuthReducer,
  myProfileReducer,
  UpdateProfileReducer,
  resetPasswordReducer,
} from './Auth'

// DASHBOARD
import {
  dashboardReducer,
  servicesGraphReducer,
  usersGraphReducer,
} from './Dashboard'

// CATEGORY
import {
  allCategoriesReducer,
  categoryDetailsReducer,
  createCategoryReducer,
  categoryReducer,
} from './Category'

// ROLES & PERMISSIONS
import {
  allPermissionsReducer,
  allRolesReducer,
  createRoleReducer,
  roleReducer,
} from './Roles-Permissions'

// PAYMENTS
import {
  allPaymentsReducer,
  resellerInvoicesReducer,
  uploadInvoiceReducer,
} from './Payments'

// BLOG
import {
  BlogCategoriesReducer,
  categoryBlogDetailsReducer,
  blogCategoryReducer,
  createBlogCategoryReducer,
  allBlogsReducer,
  newBlogsReducer,
  BlogDetailsPublicReducer,
  BlogDetailsReducer,
  createBlogReducer,
  blogReducer,
  BlogIndexDetailsReducer,
  createBlogIndexReducer,
  blogIndexReducer,
  BlogParagraphDetailsReducer,
  createBlogParagraphReducer,
  blogParagraphReducer,
} from './Blog'

// THANK YOU PAGE
import {
  allThanksReducer,
  createThanksReducer,
  thanksReducer,
  thanksListDetailsReducer,
  createThanksListReducer,
  thanksListReducer,
} from './Thankyou'

// ADMINS
import { allAdminsReducer, createAdminReducer } from './Admins'

// RESELLERS
import {
  allResellersReducer,
  resellerStatusReducer,
  createResellerReducer,
  resellerReducer,
  reUploadVerificationResellerReducer,
} from './Resellers'

// CLIENTS
import {
  allClientsReducer,
  clientRequestsReducer,
  clientStatusReducer,
  clientReducer,
} from './Clients'

// NOTIFICATIONS
import { allNotificationsReducer, notificationReducer } from './Notifications'

// PACKAGES
import { assignPackageReducer } from './Packages'

// ADMINS ACTIONS
import { actionsCountReducer } from './AdminActionsCount'

// CUSTOM FORM
import {
  allFormsReducer,
  createFormReducer,
  formDetailsReducer,
  formReducer,
  submitFormReducer,
  draftFormReducer,
  resetFormReducer,
  deleteDraftFileReducer,
} from './CustomForms'

import { createFormPartReducer, formPartReducer } from './FormPart'
import { createFormQuestionReducer, formQuestionReducer } from './FormQuestion'
import {
  createQuestionFieldReducer,
  questionFieldReducer,
} from './QuestionField'
import {
  createQuestionOptionReducer,
  questionOptionReducer,
} from './QuestionOption'

import { modalReducer } from './Modal'

const reducers = combineReducers({
  theme: Theme,
  language: languageReducer,

  userAuth: userAuthReducer,
  myProfile: myProfileReducer,
  UpdateProfile: UpdateProfileReducer,
  resetPassword: resetPasswordReducer,

  dashboard: dashboardReducer,
  servicesGraph: servicesGraphReducer,
  usersGraph: usersGraphReducer,

  allCategories: allCategoriesReducer,
  categoryDetails: categoryDetailsReducer,
  createCategory: createCategoryReducer,
  category: categoryReducer,

  allPermissions: allPermissionsReducer,
  allRoles: allRolesReducer,
  createRole: createRoleReducer,
  role: roleReducer,

  allPayments: allPaymentsReducer,
  resellerInvoices: resellerInvoicesReducer,
  uploadInvoice: uploadInvoiceReducer,

  BlogCategories: BlogCategoriesReducer,
  blogCategory: blogCategoryReducer,
  BlogDetailsPublic: BlogDetailsPublicReducer,
  categoryBlogDetails: categoryBlogDetailsReducer,
  createBlogCategory: createBlogCategoryReducer,
  allBlogs: allBlogsReducer,
  newBlogs: newBlogsReducer,
  BlogDetails: BlogDetailsReducer,
  createBlog: createBlogReducer,
  blog: blogReducer,
  BlogIndexDetails: BlogIndexDetailsReducer,
  createBlogIndex: createBlogIndexReducer,
  blogIndex: blogIndexReducer,
  BlogParagraphDetails: BlogParagraphDetailsReducer,
  createBlogParagraph: createBlogParagraphReducer,
  blogParagraph: blogParagraphReducer,

  allThanks: allThanksReducer,
  createThanks: createThanksReducer,
  thanks: thanksReducer,
  thanksListDetails: thanksListDetailsReducer,
  createThanksList: createThanksListReducer,
  thanksList: thanksListReducer,

  allAdmins: allAdminsReducer,
  createAdmin: createAdminReducer,

  allResellers: allResellersReducer,
  resellerStatus: resellerStatusReducer,
  createReseller: createResellerReducer,
  reseller: resellerReducer,
  reUploadVerificationReseller: reUploadVerificationResellerReducer,

  allClients: allClientsReducer,
  clientRequests: clientRequestsReducer,
  clientStatus: clientStatusReducer,
  client: clientReducer,

  allNotifications: allNotificationsReducer,
  notification: notificationReducer,

  assignPackage: assignPackageReducer,

  actionsCount: actionsCountReducer,

  allForms: allFormsReducer,
  createForm: createFormReducer,
  formDetails: formDetailsReducer,
  form: formReducer,
  submitForm: submitFormReducer,
  draftForm: draftFormReducer,
  resetForm: resetFormReducer,
  deleteDraftFile: deleteDraftFileReducer,

  createFormPart: createFormPartReducer,
  formPart: formPartReducer,

  createFormQuestion: createFormQuestionReducer,
  formQuestion: formQuestionReducer,

  createQuestionField: createQuestionFieldReducer,
  questionField: questionFieldReducer,

  createQuestionOption: createQuestionOptionReducer,
  questionOption: questionOptionReducer,

  modal: modalReducer,
})

export default reducers
