import React, { useState, useEffect } from 'react'
import { Button, Tooltip } from 'antd'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  SnippetsOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setModal } from '../../redux/actions/Modal'

import Language from './Language'
import NavProfile from './NavProfile'
import NavNotification from './NavNotification'
import FQADrawer from '../../features/admin-views/FrequentlyQuestionedAnswers/components/FQADrawer'
import ReportDownload from '../../features/admin-views/requests/components/modals/ReportDownload'
import { ReactComponent as Toggler } from '../shared-components/svgs/nav-toggler.svg'
import { ADMIN_PREFIX_PATH } from '../../configs/AppConfig'

export function HeaderNav({ navOpen, setNavOpen, collapsed, setCollapsed }) {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [title, setTitle] = useState(null)
  const [isFQADrawerOpen, setIsFQADrawerOpen] = useState(false)

  useEffect(() => {
    if (location.pathname.split('/')[3] === 'Home') setTitle(t('sidenav.Home'))
    if (location.pathname.split('/')[3] === 'Statistics')
      setTitle(t('Statistics'))
    if (location.pathname.split('/')[3] === 'Profile')
      setTitle(t('profile.title'))
    if (location.pathname.split('/')[3] === 'Forms') setTitle(t('Forms'))
    if (location.pathname.split('/')[3] === 'Categories')
      setTitle(t('Categories'))
    if (location.pathname.split('/')[3] === 'Services')
      setTitle(t('homenav.Services'))
    if (location.pathname.split('/')[3] === 'Payments')
      setTitle(t('sidenav.Payments'))

    if (location.pathname.split('/')[3] === 'Blog') setTitle(t('Blog'))
    if (location.pathname.split('/')[3] === 'Thank-you')
      setTitle(t('Thank you'))

    if (location.pathname.split('/')[3] === 'Requests')
      setTitle(t('sidenav.Requests'))
    if (location.pathname.split('/')[3] === 'Messages') setTitle(t('Messages'))
    if (location.pathname.split('/')[3] === 'FEA') setTitle(t('FEA'))
    if (location.pathname.split('/')[3] === 'Follow-up-Inquiries')
      setTitle(t('Inquiries'))
    if (location.pathname.split('/')[3] === 'FQA')
      setTitle(t('Frequently Questioned Answers'))

    if (location.pathname.split('/')[3] === 'user-packages') {
      setTitle(t('Pacchetti utente'))
    }

    if (location.pathname.split('/')[3] === 'users') {
      if (location.pathname.split('/')[4] === 'Clients')
        setTitle(t('sidenav.Clients'))
      if (location.pathname.split('/')[4] === 'Resellers')
        setTitle(t('sidenav.Resellers'))
      if (location.pathname.split('/')[4] === 'Admins')
        setTitle(t('sidenav.Admins'))
    }

    if (location.pathname.split('/')[3] === 'packages') {
      if (location.pathname.split('/')[4] === 'packages-requests')
        setTitle(t('Package Requests'))
      if (location.pathname.split('/')[4] === 'packages-management')
        setTitle(t('Requests Management'))
      if (location.pathname.split('/')[4] === 'resellers-management')
        setTitle(t('Resellers Management'))
    }

    if (location.pathname.split('/')[3] === 'login-history')
      setTitle(t('Login History'))

    if (location.pathname.split('/')[3] === 'Administration') {
      if (location.pathname.split('/')[4] === 'Roles') setTitle(t('Roles'))
      if (location.pathname.split('/')[4] === 'Permissions')
        setTitle(t('Permissions'))
      if (location.pathname.split('/')[4] === 'operator-settings')
        setTitle(t("Impostazioni dell'operatore"))
    }

    if (location.pathname.split('/')[3] === 'electricity-gas-companies')
      setTitle(t('electricity and gas companies'))
  }, [location.pathname, t])

  return (
    <>
      <div className='header-nav'>
        <div className='left'>
          <Toggler className='toggler' onClick={() => setNavOpen(!navOpen)} />

          {collapsed ? (
            <MenuUnfoldOutlined
              className='menu-breadcrumb'
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : (
            <MenuFoldOutlined
              className='menu-breadcrumb'
              onClick={() => setCollapsed(!collapsed)}
            />
          )}

          <h4>{title}</h4>
        </div>

        <div className='right'>
          {location.pathname.split('/')[3] === 'Follow-up-Inquiries' && (
            <QuestionCircleOutlined
              className='fs-4 mx-4 text-blue cursor-pointer'
              onClick={() => setIsFQADrawerOpen(true)}
            />
          )}
          {location.pathname === '/admin/dashboard/Requests/draft' && (
            <Button
              className='btn-green fs-14 fw-semibold rounded-3 mx-4'
              onClick={() => history.push(`${ADMIN_PREFIX_PATH}/Requests`)}
            >
              CAF e patronato
            </Button>
          )}
          {location.pathname === '/admin/dashboard/Requests' && (
            <>
              <Button
                className='btn-green fs-14 fw-semibold rounded-3'
                onClick={() =>
                  history.push(`${ADMIN_PREFIX_PATH}/Requests/draft`)
                }
              >
                Richieste di bozza
              </Button>
              <Tooltip title={t('Download Report')}>
                <SnippetsOutlined
                  className='fs-4 mx-4 text-blue cursor-pointer'
                  onClick={() => {
                    dispatch(
                      setModal({
                        title: t('Download Report'),
                        content: <ReportDownload />,
                        width: 500,
                      })
                    )
                  }}
                />
              </Tooltip>
            </>
          )}
          <Language />
          <NavNotification />
          <NavProfile />
        </div>
      </div>
      
      {isFQADrawerOpen &&
        location.pathname.split('/')[3] === 'Follow-up-Inquiries' && (
          <FQADrawer open={isFQADrawerOpen} setOpen={setIsFQADrawerOpen} />
        )}
    </>
  )
}

export default React.memo(HeaderNav)
